import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import Contact from "../Contact/Contact";
import Person from "../../assets/person.jfif";
import "./testimonial.css";

const Testimonial = () => {
  const testimonialID = useParams().id;
  const query = `
  {
    testimonials(id: "${testimonialID}") {
        author
        authorImage {
            url
        }
        location
        testimonial
    }
  }
  `;

  const [testimonial, setTestimonial] = useState({});
  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({
          query,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setTestimonial(response.data.testimonials);
      });
  }, []);
  console.log(testimonial);

  return testimonial &&
    Object.keys(testimonial).length === 0 &&
    testimonial.constructor === Object ? (
    <div className="loader___container">
      <Spinner
        className="main___screen___loader"
        animation="border"
        variant="dark"
      />
    </div>
  ) : (
    <>
      <div className="testimonial__section">
        <Container style={{ padding: "8em 1em" }}>
          <div className="d-flex flex-sm-row flex-column align-items-center">
            <div style={{width: "25vh", paddingBottom: "2em"}}>
              <img
                src={testimonial.authorImage.url}
                alt=""
                className="img-fluid testimonial__image"
              />
            </div>
            <div className="pl-sm-5">
              <h2>
                <strong>{testimonial.author}</strong>, {testimonial.location}
              </h2>
            </div>
          </div>
          <p />
          <p />
          <p />
          <Row
            className="shadow-lg"
            style={{ backgroundColor: "white", borderRadius: "2em" }}
          >
            <Col>
              <p
                className="lead test_cont"
                style={{
                  color: "black",
                  fontSize: "2.5vh",
                  padding: "2em 1em",
                }}
              >
                {testimonial.testimonial}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Contact />
    </>
  );
};

export default Testimonial;
