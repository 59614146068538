import React, { useState } from "react";
import "./bookSession.css";
import UsersSvg from "../../assets/users.svg";
import arrow from "../../assets/arrow.svg";
import Slider from "react-slick";
import Contact from "../Contact/Contact";
import leaf from "../../assets/leaf.svg";
import firebase from "../../utils/firebase";
import { Container } from "react-bootstrap";
// import leaf from "../../assets/leaf.svg";

export default function BookSession() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  const [serviceChoosen, setServices] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({});

  const services = [
    "Coaching for Concious Living",
    "Meditation to Harness the Power of Intuition",
    "Yoga Postures to Adapt to Life's Challenges",
    "Spiritual Healing to Bring Harmony Within",
  ];

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const handleServiceChange = (e) => {
    const temp = [...serviceChoosen];
    temp.includes(e.target.value)
      ? temp.splice(temp.indexOf(e.target.value), 1)
      : temp.push(e.target.value);
    setServices(temp);
  };
  const handleSubmit = (e) => {
    let errors = {};
    setError({});
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name === "" || name === null || name === undefined) {
      errors = { ...errors, name: "Name cannot be Empty" };
    }

    if (email === "" || email === null || email === undefined) {
      errors = { ...errors, email: "email cannot be Empty" };
    }

    if (regex.test(email) === false) {
      errors = { ...errors, email: "Invalid Email address" };
    }

    setError(errors);

    if (Object.keys(errors).length === 0) {
      console.log("success");
    }

    console.log(name, email, phone, text, serviceChoosen);
    const db = firebase.firestore();
    db.collection("consultations").add({
      name,
      email,
      phone,
      serviceChoosen,
      text,
    });
    setSubmitted(true);
  };
  return submitted ? (
    <>
      <div className="postSubmission">
        <Container className="d-flex flex-column align-items-center thanksCont">
          <div style={{ width: "20vh" }}>
            <img src={leaf} alt="" className="img-fluid" />
          </div>
          <h1>
            Thank you for submitting the details, we will get back to you
            shortly!
          </h1>
          <a href="/" style={{ paddingTop: "2em", color: "#333D91" }}>
            <h5 style={{ color: "#333D91" }}>Go home &#8594;</h5>
          </a>
        </Container>
      </div>
    </>
  ) : (
    <>
      <section className="bookSessionSection">
        <div className="book-form-sec book-sec-cont">
          <div className="d-flex">
            <img
              className="img-fluid"
              src={leaf}
              style={{ height: "1.75rem", marginTop: "0.2em" }}
              alt=""
            />
            <h3 className="book-heading">Personal Details</h3>
          </div>
          <div className="book-form">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={name}
              onChange={handleNameChange}
              required
            />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Phone No."
              value={phone}
              onChange={handlePhoneChange}
              required
            />
          </div>
        </div>

        <div className="chooseService book-sec-cont">
        <div className="d-flex">
            <img
              className="img-fluid"
              src={leaf}
              style={{ height: "1.75rem", marginTop: "0.2em" }}
              alt=""
            />
            <h3 className="book-heading">Choose from our Offerings</h3>
          </div>
          <div className="chooseServiceMenu">
            {services.map((service, i) => (
              <>
                <input
                  type="checkbox"
                  name="services"
                  value={service}
                  id={`service-${i}`}
                  onChange={handleServiceChange}
                />
                <label key={i} for={`service-${i}`} className="serviceBox">
                  {service}
                </label>
              </>
            ))}
          </div>
        </div>

        <div className="chooseService book-sec-cont">
        <div className="d-flex">
            <img
              className="img-fluid"
              src={leaf}
              style={{ height: "1.75rem", marginTop: "0.2em" }}
              alt=""
            />
            <h3 className="book-heading">What are you looking for?</h3>
          </div>
          <div className="book-form">
            <textarea
              name="desc"
              id="desc"
              placeholder="What are you looking for?"
              onChange={handleTextChange}
              required
            />
          </div>
          <div
            style={{ width: "100%", textAlign: "center", paddingTop: "2em" }}
          >
            <button
              className="btn btn-homeBlogs shadow-lg"
              style={{
                backgroundImage:
                  "linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);",
              }}
              onClick={handleSubmit}
            >
              Submit &#8594;
            </button>
          </div>
        </div>
      </section>
      <Contact isWhite />
    </>
  );
}
