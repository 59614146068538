import React from 'react'
import './contact.css'
import { Link, useHistory } from 'react-router-dom'
import Medium from '../../assets/medium.svg'
import Instagram from '../../assets/instagram.svg'
import Facebook from '../../assets/facebook.svg'
import Logo from '../../assets/favicon.svg'
import Arrow from '../../assets/arrow.svg'
import { Container } from 'react-bootstrap'

export default function Contact ({ isWhite = false }) {
  return (
    <div className='contact-wrapper'>
      <div className='contact-layout'>
        <div className='contact-social-media'>
          <div className='navbar_brand' style={{ justifyContent: 'left' }}>
            <img className='nav_logo' src={Logo} alt='chaityak' />
            <p style={{ color: '#ffffffc0', paddingLeft: '0.5em', fontSize: '3vw' }}><a href='/'>CHAITYAK</a></p>
          </div>
          <div className='contact-heading'>Follow Us Here</div>
          <div className='social-icons-wrapper d-flex'>
            <div
              className='social-icon'
              onClick={() =>
                window.open('https://medium.com/@Chaityak', '_blank')}
            >
              <img src={Medium} alt='social media' />
            </div>
            <div
              className='social-icon'
              onClick={() =>
                window.open('https://instagram.com/chaityak_', '_blank')}
            >
              <img src={Instagram} alt='social media' />
            </div>
            <div
              className='social-icon'
              onClick={() =>
                window.open('https://www.facebook.com/chaityak/', '_blank')}
            >
              <img src={Facebook} alt='social media' />
            </div>
          </div>
        </div>
        <div className='contact-sitemap flex-sm-row flex-column'>
          <ul>
            <li>
              <Link to='/' className='footer-link'>
                Home
              </Link>
            </li>
            <li>
              <Link to='/about' className='footer-link'>
                About Us
              </Link>
            </li>
            <li>
              <Link to='/blogs' className='footer-link'>
                Enlightenment
              </Link>
            </li>
            <Link to='/session' className='footer-link'>
              Consult Us
            </Link>
            <hr className="footer__ul__break"/>
          </ul>
          <ul>
            <li>
              <Link to='/coaching' className='footer-link'>
                Coaching for Conscious Living
              </Link>
            </li>
            <li>
              <Link to='/meditation' className='footer-link'>
                Meditation to Harness the Power of Intuition
              </Link>
            </li>
            <li>
              <Link to='/yoga' className='footer-link'>
                Yoga Practices to Adapt to Life's Challenges
              </Link>
            </li>
            <li>
              <Link to='/spiritual_healing' className='footer-link'>
                Spiritual Healing to Bring Harmony Within
              </Link>
            </li>
            <hr className="footer__ul__break"/>
          </ul>
        </div>
      </div>
      <div className='branding__strip'>
        <p className='branding__text'>
          © 2020 Chaityak. All rights reserved | Designed and Developed by{' '}
          <a
            className='metamarketing'
            href='https://www.themetamarketing.com'
            target='_blank'
            rel='noopener'
          >
            Meta Marketing
          </a>{' '}
          The material on this website may not be reproduced, distributed,
          transmitted, cached, or otherwise used, except without prior written
          permission from Chaityak.
        </p>
      </div>
    </div>
  )
}
