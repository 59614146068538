import React, { useRef, useState, useEffect } from "react";
import logo from "../../assets/favicon.svg";
import "./navbar.css";
import dot from "../../assets/dot.svg";
import { NavLink, withRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Squash as Hamburger } from "hamburger-react";
import { Accordion, Card, Nav, Navbar, NavDropdown } from "react-bootstrap";

import "./navbar.scss";

const Header = () => {
  const navHamRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [offeringsOpen, setOfferingsOpen] = useState(false);
  const [eltOpen, setEltOpen] = useState(false);
  const handleHamClick = (e, x) => {
    // console.log(navHamRef);
    if (x && x === "x") {
      navHamRef.current.style.display = "none";
      document.body.style.overflow = "scroll";
      setOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen === true) {
      navHamRef.current.style.display = "flex";
      document.body.style.overflow = "hidden";
      // setOpen(1);
    } else {
      navHamRef.current.style.display = "none";
      document.body.style.overflow = "scroll";
      // setOpen(0);
    }
  }, [isOpen]);
  return (
    <>
      <div className="navbar_cont nav-fixed shadow-lg d-flex align-items-center">
        <div className="navbar_brand">
          <img
            className="nav_logo"
            src={logo}
            alt="chaityak"
            style={{ margin: "0.5em" }}
          />
          <p>
            <Link onClick={(e) => handleHamClick(e, "x")} to="/">
              CHAITYAK
            </Link>
          </p>
        </div>
        <div className="nav_main disp_none">
          <p>
            <Link to="/">Home</Link>
          </p>
          <p>
            <img className="nav_dot" src={dot} alt="." />
          </p>
          <NavDropdown
            title={
              <div className="nav_main">
                <p>Our Offerings</p>
              </div>
            }
            id="collasible-nav-dropdown"
            onMouseEnter={() => setOfferingsOpen(true)}
            onMouseLeave={() => setOfferingsOpen(false)}
            show={offeringsOpen}
            className="nav__dropdown"
          >
            <NavDropdown.Item href="/coaching">
              Coaching for Conscious Living
            </NavDropdown.Item>
            <NavDropdown.Item href="/meditation">
              Meditation to Harness the Power of Intuition
            </NavDropdown.Item>
            <NavDropdown.Item href="/yoga">
              Yoga Practices to Adapt to Life's Challenges
            </NavDropdown.Item>
            <NavDropdown.Item href="/spiritual_healing">
              Spiritual Healing to Bring Harmony Within
            </NavDropdown.Item>
          </NavDropdown>
          <p>
            <img className="nav_dot" src={dot} alt="." />
          </p>
          <p>
            <Link to="/session">Consult Us</Link>
          </p>
          <p>
            <img className="nav_dot" src={dot} alt="." />
          </p>
          <p>
            <Link to="/blogs">Enlightenment</Link>
          </p>
          {/* <NavDropdown
          title={
            <div className="nav_main">
              <p>Enlightenments</p>
            </div>
          }
          id="collasible-nav-dropdown"
          onMouseEnter={() => setEltOpen(true)}
          onMouseLeave={() => setEltOpen(false)}
          show={eltOpen}
          className="nav__dropdown"
        >
          <NavDropdown.Item href="/blogs">Blogs & Articles</NavDropdown.Item>
          <NavDropdown.Item href="#">Videos</NavDropdown.Item>
        </NavDropdown> */}
          <p>
            <img className="nav_dot" src={dot} alt="." />
          </p>
          <p>
            <Link to="/about">About Us</Link>
          </p>
        </div>
        {/* <div className="nav_contact disp_none">
        <button>Contact</button>
      </div> */}
        <div className="nav_ham">
          <Hamburger
            duration={0.4}
            // onClick={handleHamClick}
            toggled={isOpen}
            toggle={setOpen}
          />
        </div>
        <div ref={navHamRef} className="nav_ham_menu">
          <p>
            <Link onClick={(e) => handleHamClick(e, "x")} to="/">
              Home
            </Link>
          </p>
          <Accordion className="nav__dropdown">
            <Accordion.Toggle as={Nav.Link} eventKey="0">
              Our Offerings
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div style={{ textAlign: "center" }}>
                <p>
                  <Link onClick={(e) => handleHamClick(e, "x")} to="/coaching">
                    Coaching for Concious Living
                  </Link>
                </p>
                <p>
                  <Link
                    onClick={(e) => handleHamClick(e, "x")}
                    to="/meditation"
                  >
                    Meditations to harness the power of Intuition
                  </Link>
                </p>
                <p>
                  <Link onClick={(e) => handleHamClick(e, "x")} to="/yoga">
                    Yoga Practices to Adapt to Life's challenges
                  </Link>
                </p>
                <p>
                  <Link
                    onClick={(e) => handleHamClick(e, "x")}
                    to="/spiritual_healing"
                  >
                    Spiritual Healing to bring Harmony Within
                  </Link>
                </p>
              </div>
            </Accordion.Collapse>
          </Accordion>
          <p>
            <Link onClick={(e) => handleHamClick(e, "x")} to="/session">
              Consult Us
            </Link>
          </p>
          <p>
            <Link onClick={(e) => handleHamClick(e, "x")} to="/blogs">
              Enlightenment
            </Link>
          </p>
          <p>
            <Link onClick={(e) => handleHamClick(e, "x")} to="/about">
              About Us
            </Link>
          </p>
        </div>
      </div>
      <div className="navbar_cont nav-cont-mob shadow-lg d-flex align-items-center" />
    </>
  );
};

export default withRouter(Header);
