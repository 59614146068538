import React, { useState, useEffect } from "react";
import "./HomeServices.css";
import leaf from "../../../assets/leaf.svg";
import mediImage from "../../../assets/landing_yogi.png";
import coachingImage from "../../../assets/landing_coaching.png";
import yogaImage from "../../../assets/landing_yoga.png";
import spiritualImage from "../../../assets/landing_spiritual.png";
import { useHistory } from "react-router";

const HomeServices = () => {
  const [content] = useState({
    Coaching:
      "It is better to conquer yourself than to win a thousand battles. It is offered as a thorough course for self-awareness that achieves a move in the manner you see and experience your life, your work, and the world that you live in. The target of the program is to deliver you to investigate your most elevated potential through incredible cycles of self-change, the refined pith of old-style yoga, contemplations to address key parts of life.",
    Meditation:
      'We are experiencing a daily reality that conditions us to accept that external fulfillments can give us what we need. Once more and again our encounters show us that nothing outer can satisfy the profound aching inside for "something else." All power of knowing acquires its capacity from instinct. The most elevated articulation of instinct is that by which the spirit knows Itself: The knower, knowing, and referred to exist as one Soul and Spirit, and all inward facts can be captured simply by building up the force of instinct by customary profound reflection.',
    Yoga:
      "It is a set of breathing exercises, meditation and poses that help reduce stress and enhance relaxation. Modern yoga is most commonly associated with the physical practice of asana, a series of postures often weaved together in styles such as Vinyasa Flow or Ashtanga. Asana practice is generally intended to build strength and stamina, to improve flexibility, coordination and balance, and to relax the body. However, this provides only one small aspect of the tradition of yoga as a whole.",
    "Spiritual Healing":
      'Attempting to defeat different sorts of enduring by the restricted force of material healing techniques is regularly disillusioning. Just in the limitless force of profound techniques may man locate a lasting solution for the "dis-ease" of body, psyche, and soul. That vast force of recuperating is to be looked for in God. If you have endured intellectually over the deficiency of friends and family, you can discover them again in God. This is the reason contemplation is key to Ananda Yoga, with asanas, pranayama methods, and the Energization Exercises assuming significant strong jobs.',
  });

  const [images] = useState({
    Coaching: coachingImage,
    Meditation: mediImage,
    Yoga: yogaImage,
    "Spiritual Healing": spiritualImage,
  });

  const [current, setCurrent] = useState("Coaching");
  const history = useHistory();

  const handleClick = (e, i) => {
    console.log(e, i);
    const menuItem = document.querySelectorAll(".service_nav_menu_item");
    console.log(menuItem);
    menuItem.forEach((item, idx) => {
      if (idx === i) {
        item.classList.add("service_nav_active");
      } else {
        item.classList.remove("service_nav_active");
      }
      setCurrent(e.target.innerText);
    });
  };

  useEffect(() => {
    const menuItem = document.querySelectorAll(".service_nav_menu_item");
    // setMenu(menuItem);
    console.log(menuItem);
    menuItem.forEach((item, i) => {
      // console.log(menu);
      item.addEventListener("click", (e) => handleClick(e, i));
    });
    return menuItem.forEach((item, i) => {
      // console.log(menu);
      item.removeEventListener("click", (e) => handleClick(e, i));
    });
  }, []);

  return (
    <section className="services">
      <div className="service_heading">
        <img src={leaf} alt="" />
        <h1>Our Offerings</h1>
      </div>

      <div className="d-flex flex-sm-row flex-column services_cont">
        <div className="d-flex flex-sm-column flex-row services_options w-25">
          <p className="service_nav_menu_item service_nav_active">Coaching</p>
          <p className="service_nav_menu_item">Meditation</p>
          <p className="service_nav_menu_item">Yoga</p>
          <p className="service_nav_menu_item">Spiritual Healing</p>
        </div>
        <div
          className="service_desc w-75"
          style={{
            width: "100%",
            borderRadius: "3em 3em 3em 3em",
          }}
        >
          <div
            className="service_desc_cont d-flex flex-sm-row flex-column align-items-center"
            style={{ height: "100%" }}
          >
            <div className="service_desc_p d-flex align-items-center order-sm-0 order-1 justify-content-center">
              <p class="lead" style={{ fontSize: "medium" }}>
                {content[current]}
                <br />
                <br />
                <a href={`/${current.toLowerCase().replace(" ", "_")}`}>
                  <small
                    style={{
                      fontSize: "large",
                      fontWeight: "bold",
                      color: "#333D91",
                    }}
                  >
                    Know More &#8594;
                  </small>
                </a>
              </p>
            </div>
            <img
              className="order-sm-1 order-0"
              id="service_big"
              src={images[current]}
              alt="chaityak services"
            />
          </div>
        </div>
      </div>

      {/* <div className="service_main">
        <div className="service_nav">
          <p className="service_nav_menu_item service_nav_active">Coaching</p>
          <p className="service_nav_menu_item">Meditation</p>
          <p className="service_nav_menu_item">Yoga</p>
          <p className="service_nav_menu_item">Spiritual Healing</p>
        </div>
        <div className="service_content">
          <p class="lead" style={{ fontSize: "medium" }}>
            {content[current]}
          </p>
          <img id="service_big" src={mediImage} alt="chaityak services" />
          <img
            id="service_mobile"
            src={servicesMobile}
            alt="chaityak services"
          />
          <div
            className="service_button"
            id="service_button_mobile"
            onClick={() =>
              history.push(`/${current.toLowerCase().replace(" ", "_")}`)
            }
          >
            <button>
              More Details
              <img src={arrow} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="service_button"
        id="service_button_big"
        onClick={() =>
          window.open(`/${current.toLowerCase().replace(" ", "_")}`, "_self")
        }
      >
        <button>
          More Details
          <img src={arrow} alt="" />
        </button>
      </div> */}
    </section>
  );
};
export default HomeServices;
