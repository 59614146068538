import React from 'react'
import { useHistory } from 'react-router'

function FeedbackCard ({ text, cred, image, location, id }) {
  const history = useHistory()
  return (
    <div className='fdbc_container d-flex flex-column align-content-stretch shadow'>
      <div className='d-flex align-items-center justify-content-center w-100'>
        <div className='test__image'>
          <img src={`${image}`} alt='' className='img-fluid shadow-lg' />
        </div>
      </div>
      <div className='fdbc_main'>
        <p>
          <q>{text}</q>
        </p>
        <a href={`/testimonial/${id}`}>
          <small style={{ cursor: 'pointer' }}>
            <b>Read More &#8594;</b>
          </small>
        </a>
      </div>
      <div className='fdbc_cred'>
        <p>
          <i>-{cred}, {location}</i>
        </p>
      </div>
    </div>
  )
}
export default FeedbackCard
