import React, { useEffect, useState } from "react";
import { Container, Figure, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import Contact from "../Contact/Contact";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "./blog.css";
import { Helmet } from "react-helmet";

const Blog = () => {
  const params = useParams();
  const blogID = params.id;
  const query = `
  {
    blogs(id: "${blogID}") {
        title
        shortDescription
        blogImage {
            url
        }
        publishDate
        blogContent {
            json
        }
    }
  }
  `;
  const [blog, setBlog] = useState({});
  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({
          query,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBlog(response.data.blogs);
      });
  }, []);
  console.log(blog);
  return blog &&
    Object.keys(blog).length === 0 &&
    blog.constructor === Object ? (
    <div className="loader___container">
      <Spinner
        className="main___screen___loader"
        animation="border"
        variant="dark"
      />
    </div>
  ) : (
    <>
      <Helmet>
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.shortDescription} />
        <meta property="og:image" content={blog.blogImage.url} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.shortDescription} />
        <meta name="twitter:image" content={blog.blogImage.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container style={{ paddingBottom: "3em" }}>
        <div className="row" style={{ paddingBottom: "20px" }}>
          <Figure>
            <Figure.Image
              className="img-fluid"
              src={blog.blogImage.url}
              style={{ width: "100%" }}
            />
          </Figure>
        </div>
        <h1 class="display-4" style={{ color: "black", fontSize: "5.5vh" }}>
          <strong>
            <b style={{ color: "black" }}>{blog.title}</b>
          </strong>
        </h1>
        <small>
          <strong style={{ color: "black" }}>
            {" "}
            {new Date(blog.publishDate).toDateString()}
          </strong>
        </small>
        <br />
        <br />
        <div className="blog__text" style={{ fontSize: "medium" }}>
          {documentToReactComponents(blog.blogContent.json)}
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default Blog;
