import React from "react";
import { Col, Row } from "react-bootstrap";
import AboutImg from "../../assets/aboutus.png";
import AboutLogo from "../../assets/about_logo.png";
import Contact from "../Contact/Contact";

import "./aboutus.css";

const AboutUs = () => {
  return (
    <div>
      <div className="about__us__section">
        <Row className="about__us__cont">
          <Col lg={6}>
            <img className="img-fluid" src={AboutImg} alt="" />
          </Col>
          <Col lg={6} className="chaityak__desc__cont">
            <h1 className="display-4">
              <strong>
                About
                <br />
                Chaityak
              </strong>
            </h1>
            <br />
            <p className="lead">
              Chaityak is an organization founded by individuals who are
              dedicated to partner with sincere & committed people to achieve
              their highest potential.
            </p>
            <p className="lead">
              The practitioners are dedicated to the teachings of the great
              Master Paramhansa Yogananda who was an Indian monk, yogi, and guru
              who introduced millions to the teachings of meditation and Kriya
              Yoga through his organization Self-Realization Fellowship (SRF) /
              Yogoda Satsanga Society (YSS) of India.
            </p>
            <p className="lead">
              The practitioners work towards bringing in the spiritual teachings
              of the great master in practice in daily lives, sharing practical
              tools & tips for overcoming the recurring patterns & obstacles
              that stop them from realizing their full potential & help them to
              create a powerful inner sanctuary.
            </p>
          </Col>
        </Row>
      </div>

      <div className="about__us__section">
        <Row className="about__us__cont">
          <Col lg={6} className="chaityak__desc__cont">
            <h1 className="display-4">
              <strong>
                Significance of
                <br />
                Our Logo
              </strong>
            </h1>
            <br />
            <p className="lead">
              Our Logo represents the journey of body bound individuals to a
              state where they realize their true nature and power and soar
              upwards to a state of liberation from whatever holds them back to
              achieve their higher goals in life.
            </p>
            <p className="lead">
              It also reflects how an individual can expand their awareness of
              little self to infinity by merely transitioning into a conscious
              way of living by working from within on their thoughts & feelings
              and develop new understanding of their own reality.
            </p>
            <p className="lead">
              We welcome you to progress on your journey to Enlightnment with us
              !!
            </p>
          </Col>
          <Col lg={6}>
            <img className="img-fluid" src={AboutLogo} alt="" />
          </Col>
        </Row>
      </div>
      <Contact />
    </div>
  );
};

export default AboutUs;
