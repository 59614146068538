import React from "react";
import "./homeT.css";
import Slider from "react-slick";
import leftArrow from "../../../assets/leftArrow.svg";
import rightArrow from "../../../assets/rightArrow.svg";
import FeedbackCard from "./card";

class HomeTestimonial extends React.Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.query = `
  {
    testimonialsCollection(order: sys_id_ASC) {
      items {
        sys {
          id
        }
        author
        location
        authorImage {
          url
        }
        testimonial
      }
    }
  }
  `;

    this.state = {
      slideIndex: 0,
      updateCount: 0,
      testimonials: [
        {
          author: "Andrea Johnson",
          authorImage: {
            url:
              "https://images.ctfassets.net/vzq179tic3ht/4CJwuFX9qPJeE6OkXxRGjK/190a0cfbe2d3a29de997dd879e9d936e/download__1_.png",
          },
          location: "USA",
          sys: { id: "4HBqeGHdt8yymPWMGSH1e6" },
          testimonial:
            "We used Web name to build our mobile app and website they were fabulous highery professional parteners. Their work was wonderfuly original, the account team was also responsive to feedback, and the deliverd every aspect of the project on time and budget. We look to working with them on the next phase of our project.",
        },
        {
          author: "Andrea Johnson",
          authorImage: {
            url:
              "https://images.ctfassets.net/vzq179tic3ht/4CJwuFX9qPJeE6OkXxRGjK/190a0cfbe2d3a29de997dd879e9d936e/download__1_.png",
          },
          location: "USA",
          sys: { id: "4HBqeGHdt8yymPWMGSH1e6" },
          testimonial:
            "We used Web name to build our mobile app and website they were fabulous highery professional parteners. Their work was wonderfuly original, the account team was also responsive to feedback, and the deliverd every aspect of the project on time and budget. We look to working with them on the next phase of our project.",
        },
        {
          author: "Andrea Johnson",
          authorImage: {
            url:
              "https://images.ctfassets.net/vzq179tic3ht/4CJwuFX9qPJeE6OkXxRGjK/190a0cfbe2d3a29de997dd879e9d936e/download__1_.png",
          },
          location: "USA",
          sys: { id: "4HBqeGHdt8yymPWMGSH1e6" },
          testimonial:
            "We used Web name to build our mobile app and website they were fabulous highery professional parteners. Their work was wonderfuly original, the account team was also responsive to feedback, and the deliverd every aspect of the project on time and budget. We look to working with them on the next phase of our project.",
        },
      ],
    };
  }

  componentDidMount() {
    const q = this.query;
    console.log(q);
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        body: JSON.stringify({
          query: q,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          testimonials: response.data.testimonialsCollection.items,
        });
      });
  }

  handleNext() {
    this.slider.slickNext();
  }

  handlePrevious() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnFocus: true,
      pauseOnHover: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            dots: false,
          },
        },
      ],
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };

    console.log(this.state.testimonials);
    return (
      <div className="testimonial">
        <h2 style={{ padding: "1em 0" }} className="t-heading">
          Testimonials
        </h2>
        <div className="t-back-theme">
          <Slider
            ref={(slider) => (this.slider = slider)}
            {...settings}
            className="t-main d-flex flex-column align-content-stretch"
          >
            {this.state.testimonials.map((feed, i) => {
              return (
                <div
                  className="t-main-content d-flex flex-column align-content-stretch"
                  key={i}
                >
                  <FeedbackCard
                    id={feed.sys.id}
                    text={`${feed.testimonial.substring(0, 255)}...`}
                    cred={feed.author}
                    image={feed.authorImage.url}
                    location={feed.location}
                  />
                </div>
              );
            })}
          </Slider>
          <div className="t-button">
            <button onClick={this.handlePrevious}>
              <img src={leftArrow} alt="left" />
            </button>
            <button onClick={this.handleNext}>
              <img src={rightArrow} alt="" />
            </button>
            <input
              onChange={(e) => this.slider.slickGoTo(e.target.value)}
              value={this.state.slideIndex}
              type="range"
              min={0}
              max={this.state.testimonials.length - 1}
              id="t-slider-inp"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default HomeTestimonial;
