import React from 'react'
import leaf from '../../../assets/leaf.svg'
import Contact from '../../Contact/Contact'
import './yoga.css'

const Yoga = () => {
  return (
    <>
      <div class='passion-header' style={{ backgroundColor: '#FFDE59' }}>
        <div class='row' style={{ padding: '0px' }}>
          <div class='container h-100'>
            <div class='row h-100'>
              <div class='col-12 h-100'>
                <div class='d-flex flex-column justify-content-center h-100'>
                  <div class='d-flex'>
                    <div
                      className='service_heading'
                      style={{ padding: '2em 0' }}
                    >
                      <img src={leaf} alt='' />
                      <h1 style={{ color: 'black' }}>
                        Yoga Postures to Adapt to Life's Challenges
                      </h1>
                    </div>
                  </div>
                  <div class='d-flex flex-row' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='detail-1' class='container'>
        <div class='row'>
          <div class='col-12 col-lg-6 d-flex flex-column justify-content-center pr-lg-5'>
            <h2 class='bold-weight large-heading mt-3'>
              Yoga is the journey of the self through the self.
            </h2>
            <p />
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Ananda Yoga utilizes asana and pranayama to stir, insight, and
              control the unpretentious energies (prana) inside the body,
              particularly the energies of the chakras, assumed energy places
              that are masterminded along the spine. Its item is to utilize
              those energies to blend the body, elevate the brain, or more all
              to adjust to more significant levels of mindfulness.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              A special component of this framework is the utilization of quiet
              confirmations while in the asanas, as a method for working all the
              more deliberately with the unpretentious energies to accomplish
              this attunement.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              This is the reason contemplation is key to Ananda Yoga, with
              asanas, pranayama methods, and the Energization Exercises assuming
              significant strong jobs. Together, they help the professional draw
              upon the heavenly force that by itself brings self-acknowledgement
              and enduring joy.
            </p>
          </div>
          <div
            class='col-12 col-lg-6 text-image-cover-background'
            id='yoga-right'
          />
        </div>
      </div>
      <div
        class='container-fluid component-right-text-image-background d-flex'
        id='yoga-left'
      >
        <div class='container pt-5 pb-5 align-self-center'>
          <div class='row justify-content-end'>
            <div class='col-12 col-lg-6'>
              <h2
                class='bold-weight large-heading mt-3'
                style={{ color: 'white' }}
              >
                You will experience
              </h2>
              <p />
              <ul style={{ color: 'white' }}>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Increased focus and energy
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Become more flexible and bendy
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Calmer, lighter and more associated with yourself
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Greater physical strength as well as emotional resilience
                  </p>
                </li>
              </ul>
              <p />
              <button
                className='btn btn-homeBlogs'
                onClick={() => window.open('/session', '_self')}
                style={{ width: 'auto' }}
              >
                Book A Free Consultation Now &#8594;
              </button>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  )
}

export default Yoga
