import React from 'react'
import { Button } from 'react-bootstrap'
import leaf from '../../../assets/leaf.svg'
import Contact from '../../Contact/Contact'
import './coaching.css'

const Coaching = () => {
  return (
    <>
      <div class='passion-header' style={{ backgroundColor: '#FFDE59' }}>
        <div class='row' style={{ padding: '0px' }}>
          <div class='container h-100'>
            <div class='row h-100'>
              <div class='col-12 h-100'>
                <div class='d-flex flex-column justify-content-center h-100'>
                  <div class='d-flex'>
                    <div
                      className='service_heading'
                      style={{ padding: '2em 0' }}
                    >
                      <img src={leaf} alt='' />
                      <h1 style={{ color: 'black' }}>
                        Coaching for Concious Living
                      </h1>
                    </div>
                  </div>
                  <div class='d-flex flex-row' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='detail-1' class='container'>
        <div class='row'>
          <div class='col-12 col-lg-6 d-flex flex-column justify-content-center pr-lg-5'>
            <h2 class='bold-weight large-heading mt-3'>
              It is better to conquer yourself than to win a thousand battles.
            </h2>
            <p />
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              It is offered as a thorough course for self-awareness that
              achieves a move in the manner you see and experience your life,
              your work, and the world that you live in.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              The target of the program is to deliver you to investigate your
              most elevated potential through incredible cycles of self-change,
              the refined pith of old-style yoga, contemplations to address key
              parts of life.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Conscious Living Program offers a one of a kind chance for
              self-investigation and change, prompting a daily existence of
              satisfaction and delight.
            </p>
          </div>
          <div
            class='col-12 col-lg-6 text-image-cover-background'
            id='coaching-right'
          />
        </div>
      </div>
      <div
        class='container-fluid component-right-text-image-background d-flex'
        id='coaching-left'
      >
        <div class='container pt-5 pb-5 align-self-center'>
          <div class='row justify-content-end'>
            <div class='col-12 col-lg-6'>
              <h2
                class='bold-weight large-heading mt-3'
                style={{ color: 'white' }}
              >
                How it is done
              </h2>
              <p />
              <ul style={{ color: 'white' }}>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Engage - opening up the lines of communication and allowing
                    us to engage with the client
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Educate - mentoring individual lives
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Equip / Empower - providing the tools and equipment to be
                    successful and ultimately accomplish their mission
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Elevate - elevate them reach their highest potential
                  </p>
                </li>
              </ul>

              <p />
              <button
                className='btn btn-homeBlogs'
                onClick={() => window.open('/session', '_self')}
                style={{ width: 'auto' }}
              >
                Book A Free Consultation Now &#8594;
              </button>
              {/* <a href="/session">
                <h3 style={{ paddingTop: "2em", color: "#000" }}>
                  Book Your Session Now &#8594;
                </h3>
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  )
}

export default Coaching
