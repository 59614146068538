import React from 'react'
import leaf from '../../../assets/leaf.svg'
import Contact from '../../Contact/Contact'
import './spiritual.css'

const SpiritualHealing = () => {
  return (
    <>
      <div class='passion-header' style={{ backgroundColor: '#FFDE59' }}>
        <div class='row' style={{ padding: '0px' }}>
          <div class='container h-100'>
            <div class='row h-100'>
              <div class='col-12 h-100'>
                <div class='d-flex flex-column justify-content-center h-100'>
                  <div class='d-flex'>
                    <div
                      className='service_heading'
                      style={{ padding: '2em 0' }}
                    >
                      <img src={leaf} alt='' />
                      <h1 style={{ color: 'black' }}>
                        Spiritual Healing to Bring Harmony Within
                      </h1>
                    </div>
                  </div>
                  <div class='d-flex flex-row' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='detail-1' class='container'>
        <div class='row'>
          <div class='col-12 col-lg-6 d-flex flex-column justify-content-center pr-lg-5'>
            <h2 class='bold-weight large-heading mt-3'>
              You have to learn to grow from the inside out
            </h2>
            <p />
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Attempting to defeat different sorts of enduring by the restricted
              force of material healing techniques is regularly disillusioning.
              That vast force of recuperating is to be looked for in God. If you
              have endured intellectually over the deficiency of friends and
              family, you can discover them again in God.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Spiritual healing has been characterized as the capacity of one
              method of cognizance (the mind) to spontaneously address the
              mistakes in another method of awareness (the body). By taking
              advantage of our profound established consciousness, we can fix
              ourselves at our generally essential "subatomic" level, which
              makes an establishment for genuine wellbeing, both physical and
              spiritual.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Save the essential energy, follow a decent eating regimen, and
              consistently grin and be cheerful. He who discovers euphoria
              inside himself finds that his body is accused of electric flow,
              life energy, not from food but rather from God. Until real
              acknowledgment is achieved, one should utilize his good judgment
              in everything he does.
            </p>
          </div>
          <div
            class='col-12 col-lg-6 text-image-cover-background'
            id='spiritual-right'
          />
        </div>
      </div>
      <div
        class='container-fluid component-right-text-image-background d-flex'
        id='spiritual-left'
      >
        <div class='container pt-5 pb-5 align-self-center'>
          <div class='row justify-content-end'>
            <div class='col-12 col-lg-6'>
              <h2
                class='bold-weight large-heading mt-3'
                style={{ color: 'white' }}
              >
                You will experience
              </h2>
              <p />
              <ul style={{ color: 'white' }}>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Increased positive energy and positivity in your life
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Elimination of poor health and negative thoughts
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    How to meditate effectively with the aid of a spiritual
                    healer
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    An in-depth understanding of yourself and the needs of your
                    physical and emotional being
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Bestow upon you a personal mantra to achieve a deeper
                    meditative state
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Deep breathing techniques to achieve a state of inner peace
                  </p>
                </li>
              </ul>

              <p />
              <button
                className='btn btn-homeBlogs'
                onClick={() => window.open('/session', '_self')}
                style={{ width: 'auto' }}
              >
                Book A Free Consultation Now &#8594;
              </button>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  )
}

export default SpiritualHealing
