import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Fallback from './components/Fallback/Fallback'
import Nav from './components/nav/navbar.jsx'
import Home from './components/home/home'
import BookSession from './components/BookSession/BookSession'
import Coaching from './components/Offerings/Coaching'
import Meditation from './components/Offerings/Meditation'
import SpiritualHealing from './components/Offerings/SpiritualHealing'
import Yoga from './components/Offerings/Yoga'
import Blogs from './components/Blogs'
import Blog from './components/Blog'
import AboutUs from './components/AboutUs'
import Testimonial from './components/Testimonial'
import ScrollToTop from './components/ScrollToTop'
export default function CustomRouter() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Nav />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/session' component={BookSession} />
          <Route exact path='/coaching' component={Coaching} />
          <Route exact path='/meditation' component={Meditation} />
          <Route exact path='/spiritual_healing' component={SpiritualHealing} />
          <Route exact path='/yoga' component={Yoga} />
          <Route exact path='/blogs' component={Blogs} />
          <Route exact path='/blog/:id' component={Blog} />
          <Route exact path="/testimonial/:id" component={Testimonial} />
          <Route exact path='/about' component={AboutUs} />
          <Route path='*' component={Fallback} />
        </Switch>
      </BrowserRouter>
    </>
  )
}
