import React from "react";
import "./blogComponent.css";

export default function BlogComponent({
  id,
  imgSrc,
  title,
  description,
  style = {},
}) {
  return (
    <div
      className="homeBlogComponent shadow-lg"
      style={style}
      onClick={() => window.open(`/blog/${id}`, "_self")}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <img
          src={imgSrc}
          className="embed-responsive-item"
          alt="blog description"
        />
      </div>
      <h3>{title}</h3>
      <p class="lead" style={{ fontSize: "medium" }}>
        {description}
      </p>
      <a href={`/blog/${id}`}>
        <small>
          <b>Read More &#8594;</b>
        </small>
      </a>
    </div>
  );
}
