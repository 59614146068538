import React from 'react'
import leaf from '../../../assets/leaf.svg'
import Contact from '../../Contact/Contact'
import './meditation.css'

const Meditation = () => {
  return (
    <>
      <div class='passion-header' style={{ backgroundColor: '#FFDE59' }}>
        <div class='row' style={{ padding: '0px' }}>
          <div class='container h-100'>
            <div class='row h-100'>
              <div class='col-12 h-100'>
                <div class='d-flex flex-column justify-content-center h-100'>
                  <div class='d-flex'>
                    <div
                      className='service_heading'
                      style={{ padding: '2em 0' }}
                    >
                      <img src={leaf} alt='' />
                      <h1 style={{ color: 'black' }}>
                        Meditation to Harness the Power of Intuition
                      </h1>
                    </div>
                  </div>
                  <div class='d-flex flex-row' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='detail-1' class='container'>
        <div class='row'>
          <div class='col-12 col-lg-6 d-flex flex-column justify-content-center pr-lg-5'>
            <h2 class='bold-weight large-heading mt-3'>
              Your goal is not to battle but witness the mind.
            </h2>
            <p />
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              We are experiencing a daily reality that conditions us to accept
              that external fulfillments can give us what we need. Once more and
              again our encounters show us that nothing outer can satisfy the
              profound aching inside for "something else."
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              All power of knowing acquires its capacity from instinct. The most
              elevated articulation of instinct is that by which the spirit
              knows Itself: The knower, knowing, and referred to exist as one
              Soul and Spirit, and all inward facts can be captured simply by
              building up the force of instinct by customary profound
              reflection.
            </p>
            <p
              class='lead'
              style={{
                fontSize: 'medium',
                color: '#000'
              }}
            >
              Contemplation encourages us to enter a condition of inward
              calmness. The more profoundly you contemplate, and afterward put
              your mind on some issue, the more your intuition will communicate
              in settling that issue.
            </p>
          </div>
          <div
            class='col-12 col-lg-6 text-image-cover-background'
            id='meditation-right'
          />
        </div>
      </div>
      <div
        class='container-fluid component-right-text-image-background d-flex'
        id='meditation-left'
      >
        <div class='container pt-5 pb-5 align-self-center'>
          <div class='row justify-content-end'>
            <div class='col-12 col-lg-6'>
              <h2
                class='bold-weight large-heading mt-3'
                style={{ color: 'white' }}
              >
                You will experience
              </h2>
              <p />
              <ul style={{ color: 'white' }}>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Stillness & silence away from hustle & bustle of daily
                    living
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Ability to direct & channelize our energies in the right
                    direction
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Increased focus & concentration
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Harmony within & without
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Improved relationships
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Become one with the Infinite Intelligence
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Identity consciousness as the fundamental ground of all
                    being
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Tap into deeper and more subtle levels of consciousness
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Make your mind a more dynamic center for direct perception
                  </p>
                </li>
                <li style={{ color: 'white' }}>
                  <p
                    class='lead'
                    style={{
                      fontSize: 'medium',
                      color: 'white'
                    }}
                  >
                    Unfold the potential of your mind and soul
                  </p>
                </li>
              </ul>

              <p />
              <button
                className='btn btn-homeBlogs'
                onClick={() => window.open('/session', '_self')}
                style={{ width: 'auto' }}
              >
                Book A Free Consultation Now &#8594;
              </button>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </>
  )
}

export default Meditation
