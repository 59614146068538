import React, { useState } from 'react'

import landImage1 from '../../assets/transition.jpg'
import landImage2 from '../../assets/harmony.jpg'
import landImage3 from '../../assets/adapt.jpg'

import HomeBlogs from './HomeBlogs/HomeBlogs'
import HomeServices from './HomeServices/HomeServices'
import HomeTestimonial from './HomeTestimonial/HomeTestimonial'
import { Carousel } from 'react-bootstrap'
import './home.css'
// import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
import Contact from '../Contact/Contact'
import HomeAbout from './HomeAbout'

const Home = () => {
  const word_arr = ['C', 'H', 'A', 'I', 'T', 'Y', 'A', 'K']
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }
  console.log(index)
  return (
    <>
      <Carousel
        className='embed-responsive embed-responsive-21by9'
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item className='embed-responsive-item' id='slider__image__1'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>CONSIOUSNESS</b> - Transition to Conscious Living
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='embed-responsive-item' id='slider__image__2'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>HARMONY</b> - Experience the Harmony Within
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='embed-responsive-item' id='slider__image__3'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>ADAPTABILITY</b> - Better Adapt to Life’s Challenges
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='embed-responsive-item' id='slider__image__4'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>INTUITION</b> - Harness the Power of your Intuition
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='embed-responsive-item' id='slider__image__5'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>TRUE SELF</b> - Tap into your True-Self
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='embed-responsive-item' id='slider__image__6'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>YEARNING</b> - Fulfil your Deepest Yearnings
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='embed-responsive-item' id='slider__image__7'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>ACTION</b> - Learn to Act Timely & Even-Mindedly
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='embed-responsive-item' id='slider__image__8'>
          <Carousel.Caption>
            <h1>
              <b>
                {word_arr.map((l, i) => (
                  <span
                    className={
                      index === i ? 'active_letter letters' : 'letters'
                    }
                  >
                    {l}
                  </span>
                ))}
              </b>
            </h1>
            <p>
              <i>
                <b>KINDLE</b> - To Ignite the Fire Inside you and Keep it
                Burning
              </i>
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {/* <HomeAbout /> */}
      <HomeServices />
      <HomeTestimonial />
      <HomeBlogs />
      <Contact />
    </>
  )
}
export default Home
