import React, { useEffect, useState } from 'react'
import './HomeBlogs.css'
import BlogComponent from './BlogComponent/BlogComponent'
import Arrow from '../../../assets/arrow.svg'
export default function HomeBlogs () {
  const query = `
  {
    blogsCollection(limit: 4, order: sys_id_ASC) {
      items {
        sys {
          id
        }
        title
        shortDescription
        blogImage {
          url
        }
        publishDate
      }
    }
  }
  `
  const [blogs, setBlogs] = useState([])
  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({
          query
        })
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBlogs(response.data.blogsCollection.items)
      })
  }, [])
  return (
    <>
      <div style={{ width: '100%', paddingBottom: '2em' }}>
        <h2 className='t-heading'>Enlightenment</h2>
      </div>
      <section className='homeBlogsContainer' id='enlightenment'>
        {/* <div className="homeBlogsIllustration">
        <picture>
          <source media="(max-width: 600px)" srcSet={homeBlogMobile} />
          <source media="(max-width: 950px)" srcSet={homeBlogTablet} />
          <img src={homeBlogDesk} alt="Blogs illustration" />
        </picture>
      </div> */}

        <br />
        <div className='homeBlogsWrapper'>
          {/* <div className="homeBlogs"> */}
          {blogs.map((blog) => (
            <div className='col-xl-3 col-md-6 d-flex align-items-stretch'>
              <BlogComponent
                key={blog.sys.id}
                id={blog.sys.id}
                imgSrc={blog.blogImage.url}
                title={blog.title}
                description={blog.shortDescription}
                style={{ width: '100%', cursor: 'pointer' }}
              />
            </div>
          ))}
          {/* </div> */}
          <div className='homeBlogsBtnWrapper'>
            <button
              className='btn btn-homeBlogs shadow-lg'
              onClick={() => window.open('/blogs', '_self')}
            >
              See All
              <img src={Arrow} alt='arrow' />
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
