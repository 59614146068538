import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Contact from '../Contact/Contact'
import BlogComponent from '../home/HomeBlogs/BlogComponent/BlogComponent'
import leaf from '../../assets/leaf.svg'
import './blogs.css'

const Blogs = () => {
  const query = `
  {
    blogsCollection(order: sys_id_ASC) {
      items {
        sys {
          id
        }
        title
        shortDescription
        blogImage {
          url
        }
        publishDate
      }
    }
  }
  `
  const [blogs, setBlogs] = useState([])
  useEffect(() => {
    fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`
        },
        body: JSON.stringify({
          query
        })
      }
    )
      .then((res) => res.json())
      .then((response) => {
        setBlogs(response.data.blogsCollection.items)
      })
  }, [])
  return blogs.length === 0 ? (
    <div className='loader___container'>
      <Spinner
        className='main___screen___loader'
        animation='border'
        variant='dark'
      />
    </div>
  ) : (
    <>
      <div class='passion-header' style={{ backgroundColor: '#FFDE59' }}>
        <div class='row' style={{ padding: '0px' }}>
          <div class='container h-100'>
            <div class='row h-100'>
              <div class='col-12 h-100'>
                <div class='d-flex flex-column justify-content-center h-100'>
                  <div class='d-flex'>
                    <div
                      className='service_heading'
                      style={{ padding: '2em 0' }}
                    >
                      <img src={leaf} alt='' />
                      <h1 style={{ color: 'black' }}>Blogs & Articles</h1>
                    </div>
                  </div>
                  <div class='d-flex flex-row' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-wrap' style={{ padding: '2em 5%' }}>
        {blogs.map((blog, index) => (
          <div className='col-xl-4 col-md-6 d-flex align-items-stretch'>
            <BlogComponent
              key={blog.sys.id}
              id={blog.sys.id}
              imgSrc={blog.blogImage.url}
              title={blog.title}
              description={blog.shortDescription}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
      <Contact />
    </>
  )
}

export default Blogs
